<template>
	<div class="partial partial--client client">
		[client]
		<details-client :client="client" />

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import DetailsClient from '@/components/DetailsClient'
import Modal from '@/components/Modal'

export default {
	name: 'PartialClient',
	components: {
		DetailsClient,
		Modal,
	},
	mounted() {
		if (this.$route.params.clientId) {
			this.$store.dispatch('client/get', this.$route.params.clientId)
		}
	},
	destroyed() {
		this.$store.dispatch('client/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		client() {
			return this.$store.getters['client/client']
		},
		modalVisible() {
			return this.$route.name == 'clientEdit'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},

	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.back()
			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
