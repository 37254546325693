<template>
	<div class="details details--client details-client">
		<div class="details-client__header">
			<div class="details__header-body">
				<h1 class="details__header-title">{{ client.name }}</h1>
			</div>
			<div class="details__header-trailing">
				<router-link :to="{name: 'clientEdit', params: { clientId: $route.params.clientId} }">Edit Client</router-link>
			</div>
		</div>

		<div class="details-client__body">
			<div class="details-client__info">
				<card>
					<div class="card__fields">
						{{ client }}
					</div>
				</card>

				<tab-bar
					:items="tabLinks"
					class="form-tools__nav-tabs">
					<template slot-scope="{ item, idx }" slot="item">
						<router-link
							class="tab-bar__item a"
							:id="`nav_tab_${idx}`"
							:to="item.link"
							>
							{{ item.label }}
						</router-link>
					</template>
				</tab-bar>
			</div>

			<div class="details-client__sidebar">
				[sidebar]
			</div>
		</div>

	</div>
</template>

<script>
import Card from '@/components/Card'
import TabBar from '@/components/TabBar'

export default {
	name: 'DetailsClient',
	components: {
		Card,
		TabBar,
	},
	props: {
		client: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		tabLinks: [
			{
				label: `Locations`,
				link: {},
			},
			{
				label: `Users`,
				link: {},
			},
		]
	}),
}
</script>

<style scoped lang="scss">
.details-client {

	&__header {

	}

	&__body {
		@include modules.gutter('grid-column-gap');
		@include modules.gutter('grid-row-gap');
		display: grid;
		grid-template-columns: 2.5fr 1fr;
	}
}
</style>
